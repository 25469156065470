/**
 * Site header
 */
.site-header {
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  font-size: 26px;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
	margin-right: 20px;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}
.site-title-social {
	font-size:14px;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
	margin-right: 10px;
  float: left;
  width: -webkit-calc(40% - (#{$spacing-unit} / 2));
  width:         calc(40% - (#{$spacing-unit} / 2));
}

@include media-query($on-palm) {
	.site-title-social {
		display: none;
	}
}

.site-nav {
  float: right;
  line-height: 56px;

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    .page-link {
      padding: 20px 0;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
	border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
  font-size: 15px;
  color: $grey-color;
  // text-align: center;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin: 0;
}

.footer-col-wrapper {
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.social-links a {
	// margin: 0px;
	list-style: none;
	display: inline-block;
	font-size: 1.5em;
	color: $grey-color;
	opacity: 0.65;
	padding: 0 10px 0 10px;
}

.social-links a:hover {
	opacity: 0.45;
}

.footer-col-3 {
  width: -webkit-calc(50% - (#{$spacing-unit} / 2));
  width:         calc(50% - (#{$spacing-unit} / 2));
}

.footer-attribution {
  text-align: center;
}

// @include media-query($on-laptop) {
//   .footer-col-1,
//   .footer-col-2 {
//     width: -webkit-calc(50% - (#{$spacing-unit} / 2));
//     width:         calc(50% - (#{$spacing-unit} / 2));
//   }
//
//   .footer-col-3 {
//     width: -webkit-calc(100% - (#{$spacing-unit} / 2));
//     width:         calc(100% - (#{$spacing-unit} / 2));
//   }
// }

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  .post-link:hover {
    text-decoration: none;
  }

  > li {
    margin-bottom: $spacing-unit * 2;

    &:not(:first-child) {
      border-top: 4px solid $grey-color-light;
      padding-top: $spacing-unit * 2;
    }
  }
}

.post-archives {
  margin-left: 0;
  list-style: none;

  .post-link {
    font-size: 24px;
  }

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  &,
  &:visited {
    color: $text-color;
  }

  &:hover {
    color: $brand-color;
  }
}

.post-continue {
  a {
    padding: 8px 15px;
    text-decoration: none;

    &,
    &:visited {
      color: $grey-color;
      background-color: $grey-color-light;
    }

    &:hover {
      color: white;
      background-color: $brand-color;
    }
  }
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}



/**
 * Pagination
 */
.pagination {
  padding: $spacing-unit / 2 0;
  border-top: 1px solid $grey-color-light;
  border-bottom: 1px solid $grey-color-light;
  text-align: center;
  @extend %clearfix;

  a {
    &,
    &:visited {
      color: $grey-color;
    }

    &:hover {
      color: $brand-color;
    }
  }

  .previous {
    float: left;
  }

  .next {
    float: right;
  }
}
